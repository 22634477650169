<template lang="pug">
  main.main
    book(:images="images")
</template>

<script>
import Book from "@/components/Ebook";
const images = [null]
for (let index = 1; index <= 6; index++) {
  images.push(require(`../assets/images/book2/${index}.jpg`))
}
export default {
  components: { Book },
  data: () => ({
    images,
  })
}
</script>
